import styled from "styled-components";

export const ShowcaseContainer = styled.div`
  background-image: url("/img1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  position: relative;
  display: flex;
`;

export const ColorContainer = styled.div`
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0.85;
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Permanent Marker", cursive;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 5rem;
  }
`;

export const Title = styled.h2`
  font-size: 5rem;
  text-align: center;
  letter-spacing: 5px;
  font-family: "Permanent Marker", cursive;
  color: red;
  text-shadow: 1px 2px 5px #fff;
  animation-duration: 2s;
  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

export const SubTitle = styled.h3`
  font-size: 3rem;
  text-align: center;
  letter-spacing: 10px;
  font-family: "Permanent Marker", cursive;
  text-shadow: 1px 3px 5px red;
  animation-duration: 2s;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ShowcaseButton = styled.button`
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  margin-top: 1.7rem;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  animation-duration: 2s;
  text-transform: uppercase;
`;

export const DownArrow = styled.h2`
  padding: 3rem;
  font-size: 7rem;
  animation-duration: 1.3s;
  animation-delay: 2s;
`;
