import React from "react";
import { AppContainer } from "./app.styles";
import { GlobalStyle } from "./global.styles";
import { Showcase } from "./components/showcase/showcase.component";
import { Header } from "./components/header/header.component";
import { Portfolio } from "./components/portfolio/portfolio.component";
import { Footer } from "./components/footer/footer.component";

import AOS from "aos";

export const App = () => {
  AOS.init();

  return (
    <AppContainer>
      <GlobalStyle />
      <Header />
      <Showcase />
      <Portfolio />

      <Footer />
    </AppContainer>
  );
};
