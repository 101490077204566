import React from "react";
import {
  ShowcaseContainer,
  TitleContainer,
  Title,
  SubTitle,
  ColorContainer,
  // ShowcaseButton,
  DownArrow
} from "./showcase.styles";

import { Icon } from "semantic-ui-react";

export const Showcase = () => {
  return (
    <ShowcaseContainer className="animated" >
      <ColorContainer />
      <TitleContainer>
        <Title className="animated bounceInLeft">Jayadrata Middey</Title>
        <SubTitle className="animated bounceInRight">
          Full Stack Developer
        </SubTitle>
        {/* <ShowcaseButton className="animated bounceInUp">Welcome</ShowcaseButton> */}
        <DownArrow className="animated bounce infinite">
          <Icon name="angle down"></Icon>
        </DownArrow>
      </TitleContainer>
    </ShowcaseContainer>
  );
};
