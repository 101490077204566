import React from "react";
import {
  HeaderContainer,
  BrandLogo,
  NavContainer,
  StyledLink
} from "./header.styles";

export const Header = () => {
  return (
    <HeaderContainer>
      <BrandLogo>{"jayadrata.com"}</BrandLogo>
      <NavContainer>
        <StyledLink href="#">Home</StyledLink>
        <StyledLink href="#">About</StyledLink>
        <StyledLink href="#">Portfolio</StyledLink>
        <StyledLink href="#">Contact</StyledLink>
      </NavContainer>
    </HeaderContainer>
  );
};
