import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0rem;
  border-bottom: 1px solid #df292d;

  /* box-shadow: 0 3px 10px -5px #fff; */
  background-color: #111;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  /* opacity: 0.7; */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BrandLogo = styled.div`
  /* font-size: 1.5rem; */
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-family: "Permanent Marker", cursive;
  font-size: 2.5rem;
  padding: 0.6rem 0rem;
  text-shadow: 1px 2px red;
`;
export const NavContainer = styled.div``;

export const StyledLink = styled.a`
  color: #fff;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
`;
