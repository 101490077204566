import styled from "styled-components";

export const FooterContainer = styled.div`
  background: #000;
  /* opacity: 0.9; */
  padding: 1rem;
  color: #fff;
  width: 100vw;
`;

export const FooterText = styled.span`
  

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  letter-spacing: 2px;
  /* font-family: "ZCOOL QingKe HuangYou", cursive; */
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
