import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ProjectContainer,
  ProjectTitle,
  CardContainer,
  CardGroup,
  CardContainerTitle,
  CardLinksContainer
} from "./portfolio.styles";

export const Portfolio = () => {
  const [projects, setProjects]: Array<any> = useState([]);

  useEffect(() => {
    async function fetchData() {
      const resp = await axios.get(
        "https://api.github.com/users/imjaya03/repos"
      );
      const data = resp.data;
      // console.log(data[0].name);
      setProjects(data);
    }
    fetchData();
  }, []);

  console.log("CC");

  return (
    <ProjectContainer>
      <ProjectTitle>Github Projects</ProjectTitle>
      <CardGroup>
        {projects.map(project => {
          return (
            <CardContainer key={project.id} data-aos="fade-in">
              <CardContainerTitle>{project.name}</CardContainerTitle>
              <CardLinksContainer href={project.html_url} target="blank">
                Soure Code
              </CardLinksContainer>
            </CardContainer>
          );
        })}
      </CardGroup>
    </ProjectContainer>
  );
};
