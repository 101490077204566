import styled from "styled-components";

export const ProjectContainer = styled.div`
  background: #df292d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ProjectTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  padding: 2rem;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-family: "Permanent Marker", cursive;
  width: 100%;
  padding-top: 8rem;
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export const CardGroup = styled.div`
  padding: 2rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 0.7rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CardContainer = styled.div`
  background: url("/code1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 350px; */

  position: relative;

  height: 400px;
  /* border-radius: 10px; */
  /* margin: 0.5rem; */

  @media (max-width: 768px) {
  }
`;

export const CardContainerTitle = styled.div`
  background: black;
  color: white;
  font-size: 1.5rem;
  width: 100%;
  /* height: 3rem; */
  text-align: center;
  padding: 1.5rem;
  text-transform: uppercase;
  display: grid;
  line-height: 1.5rem;
`;

export const CardLinksContainer = styled.a`
  padding: 1rem;
  background: #fff;
  position: absolute;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  bottom: 0;
`;
