import React from "react";
import { FooterContainer, FooterText } from "./footer.styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>Developed and Designied by Jayadrata Middey</FooterText>
    </FooterContainer>
  );
};
